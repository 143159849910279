const getPruebasAdapter = (data) => {
  let arrayResult = data.map(function (item) {
    let json = item;
    json.id = item.prueba;    
    json.tipo = item.tipo.value;
    return json;
  });
  return arrayResult;
};

const getPruebasDetalleAdapter = (data) => {
  console.log(data);
  const jsonResult = {
    prueba: data.id || "",
    nombre: data.nombre || "",
    tipo: data.tipo || "",
    tiempo: data.tiempo || "-1",
    instruciones: data.instruciones || "",//extraerInstrucciones(data.instruciones),
    preguntas: adaptarPreguntas(data.id, data.preguntas)
  };
  console.log(jsonResult);
  return jsonResult;
};

function adaptarPreguntas(prueba, preguntas) {
  return preguntas.map(pregunta => ({
    prueba,
    pregunta: pregunta.id || "",
    nombre: pregunta.descripcion || "",
    tipo: pregunta.tipo || "",
    jsSeccion: pregunta.jsPregunta?.seccion?.nombre || "", // Validación con encadenamiento opcional
    jsRecurso: pregunta.jsPregunta?.recurso || "", // Validación con encadenamiento opcional
    respuestas: adaptarRespuestas(prueba, pregunta.id, pregunta.respuestas)
  }));
}

function adaptarRespuestas(prueba, id, respuestas) {
  return respuestas.map(respuesta => ({
    prueba,
    pregunta: id || "",
    respuesta: respuesta.id || "",
    descripcion: respuesta.descripcion || "",
    tipo: respuesta.tipo || ""
  }));
}

function extraerInstrucciones(instrucionesHtml) {
  // Aquí puedes procesar el HTML si es necesario, por ejemplo, eliminando etiquetas
  const parser = new DOMParser();
  const doc = parser.parseFromString(instrucionesHtml, 'text/html');
  return doc.body.innerText; // Devuelve solo el texto plano
}

const pruebasAdapter = {
  getPruebasAdapter,
  getPruebasDetalleAdapter
};

export default pruebasAdapter;