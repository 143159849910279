import client from "libs/client";

const getSchools = async () => {
  let response = await client("POST", {}, "/schools");
  return response;
};

const createSchools = async (data) => {
  let response = await client("POST", data, "/schools/create");
  return response;
};

const updateSchools = async (data) => {
  let response = await client("POST", data, "/schools/update");
  return response;
};

const deleteSchools = async (data) => {
  let response = await client("POST", data, "/schools/delete");
  return response;
};

const getGrades = async (data) => {
  let response = await client("POST", data, "/schools/grade");
  return response;
};

const getClassRoom = async (data) => {
  var response = await client("POST", data, "/schools/classroom");
  return response;
};

const getStudents = async (data) => {
  let response = await client("POST", data, "/students");
  return response;
};

const getDepartaments = async () => {
  let response = await client("POST", {}, "/admin/departments");
  return response;
};

const getTiposParticipantes = async () => {
  let response = await client("POST", {}, "/participants/type");
  return response;
};

const getParticipantes = async (data) => {
  let response = await client("POST", data, "/participants");
  return response;
};

const crearParticipante = async (data) => {
  let response = await client("POST", data, "/participants/create");
  return response;
};

const actualizarParticipante = async (data) => {
  let response = await client("POST", data, "/participants/update");
  return response;
};



const paramsService = {
  getSchools,
  createSchools,
  updateSchools,
  deleteSchools,
  getGrades,
  getClassRoom,
  getStudents,
  getDepartaments,
  getTiposParticipantes,
  getParticipantes,
  crearParticipante,
  actualizarParticipante
};

export default paramsService;
