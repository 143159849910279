import React from "react";
import { TextField, Autocomplete } from "@mui/material";

const SelectAutocomplete = (props) => {
  const { name, label, id, desc, setFieldValue, options, touched, error, ...others } = props;
  // Verificamos que options sea un array antes de usarlo
  if (!Array.isArray(options)) {
    console.error('Options must be an array');
  }

  return (
    <Autocomplete
      {...others}
      disablePortal
      id={`select-${name}`} // Mejor usar template strings para construir el id
      options={options || []} // Aseguramos que siempre haya un array
      onChange={(e, value) => {
        setFieldValue(name, value ? value[id] : ""); // Aseguramos que 'value' tenga el id correcto
      }}
      getOptionLabel={(option) => option[desc] || ''} // Devolvemos el valor de 'desc', si existe
      isOptionEqualToValue={(option, value) => option[id] === value[id]} // Comparamos por id
      noOptionsText="No hay resultados"
      loadingText="Cargando..."
      renderInput={(params) => (
        <TextField
          {...params}
          error={Boolean(touched && error)} // Si hay error y el campo fue tocado, mostramos el error
          fullWidth
          helperText={touched && error} // Mostramos el mensaje de error si es necesario
          label={label}
          name={name} // Aseguramos que el 'name' se pase correctamente
          id={`input-${name}`} // Aseguramos que el 'id' también esté asignado
          variant="outlined"
        />
      )}
    />
  );
};

export default SelectAutocomplete;

/*
Ejemplo de uso

<SelectAutocomplete
                  name="prueba"
                  label="Pruebas*"
                  onOpen={handleBlur}
                  setFieldValue={setFieldValue}
                  options={pruebas}
                  error={errors.prueba}
                  touched={String(touched.prueba)}
                  id="key"
                  desc="value"
                />
*/
