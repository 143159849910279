import React from "react";
import { Button, Icon } from "@mui/material";
import { styled } from "@mui/material/styles";

const ButtonStyle = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  borderRadius: "0.5em",
  height: "3em",
  //marginTop: '1.5em',

  border: "2px dotted #1976d2",    // Borde punteado más grueso
  borderRadius: "12px",            // Bordes redondeados
  padding: "1em 2em",              // Más espacio alrededor del texto
  color: theme.palette.primary.dark,
  backgroundColor: "white",
  height: "3em",  
  "&:hover": {
    backgroundColor: theme.palette.primary.light,    // Cambia color al hacer hover
    border: "3px dotted #1976d2",
  },
}));

const ButtonBorder = (props) => {
  return (
    <ButtonStyle
      type="button"
      variant={props.variant != null ? props.variant : "contained"}
      endIcon={<Icon color="inherit">{props.iconname}</Icon>}
      {...props}
    >
      {props.description ? props.description : ""}
    </ButtonStyle>
  );
};

export default ButtonBorder;
