const getParticipantes = (data) => {
  let jsonResult = data;
  jsonResult.codigo = data.id || "";
  jsonResult.t_doc = data.t_doc || "";
  jsonResult.num_doc = data.num_doc || "";
  jsonResult.pri_nom = data.pri_nom || "";
  jsonResult.seg_nom = data.seg_nom || "";
  jsonResult.pri_ape = data.pri_ape || "";
  jsonResult.seg_ape = data.seg_ape || "";
  jsonResult.sexo = data.sexo || "";
  jsonResult.fec_nac = data.fec_nac || "";
  jsonResult.nivel_educ = data.nivel_educ || "";
  jsonResult.estrato = data.estrato || "";
  jsonResult.correo = data.correo || "";
  jsonResult.num_cel = data.num_cel || "";
  jsonResult.direc = data.direc || "";
  jsonResult.id_departamento = data.departamento || "";
  jsonResult.id_localidad = data.id_localidad || "";
  jsonResult.id_rol = data.id_rol || "";  
  return jsonResult;
};

const participantesAdapters = {
  getParticipantes,
};

export default participantesAdapters;