import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Stack } from "@mui/material";
import TextFieldCustom from "components/ui/TextFields/TextFieldCustom";
const formatDate = "DD/MM/YYYY";
const TextFieldDate = (props) => {
  const { value, setFieldValue, label, name, touched, errorDate, ...others } =
    props;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack spacing={3}>
        <DatePicker
          onChange={(value) => setFieldValue(name, value)}
          onBlur={(value) => setFieldValue(name, value)}
          value={value}
          inputFormat={formatDate}
          label={label}
          renderInput={({ error, ...params }) => (
            <TextFieldCustom
              {...others}
              label={label}
              name={name}
              error={errorDate}
              touched={touched}
              {...params}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default TextFieldDate;
