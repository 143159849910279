import React, { useState, useEffect } from "react";
import {
  Grid,
  FormHelperText,
  InputLabel,
  FormControl,
  TextField,
  Box,
  MenuItem,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TableCell,
  tableCellClasses,
  Divider,
  Tooltip,
  IconButton
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import SaveIcon from "@mui/icons-material/Save";
import TuneIcon from '@mui/icons-material/Tune';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ErrorIcon from '@mui/icons-material/Error';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

import { Formik } from "formik";
import * as Yup from "yup";
import SelectCustom from "components/ui/Selects/SelectCustom";
import paramsService from "services/params.services";
import adminService from "services/admin.services";
import { showAlertSuccess, showAlertError, showAlertInfo, showAlertWarning } from "utils/alert";

import moment from "moment";
import DateTimer from "components/DateTimer";
import estudianteService from "services/estudiantes.services";
import SelectAutocomplete from "components/ui/Selects/SelectAutocomplete";

const initialValues = {
  colegio: "",
  grado: "",
  grupo: "",
  codEstudiante: "",
};

const formSchema = Yup.object().shape({
  colegio: Yup.string(),
  grado: Yup.string(),
  grupo: Yup.string(),
  codEstudiante: Yup.string(),
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function UpdateEstudianteForm({ setOpenLoading }) {
  const [valueTemp, setValueTemp] = useState(null);

  const [colegios, setColegios] = useState([]);
  const [grados, setGrados] = useState([]);
  const [grupos, setGrupos] = useState([]);
  // Enums
  const [enumGrados, setEnumGrados] = useState([]);
  const [enumGrupos, setEnumGrupos] = useState([]);
  const [enumGeneros, setEnumGeneros] = useState([]);
  const [enumEstratos, setEnumEstratos] = useState([]);
  // ParamsConsulta
  const [colegio, setColegio] = useState(null);
  const [grado, setGrado] = useState(null);
  const [grupo, setGrupo] = useState(null);

  const [estudiantes, setEstudiantes] = useState([]);
  const [estudiantesToUpdate, setEstudiantesToUpdate] = useState([]);
  const [filterCode, setFilterCode] = useState("filterCode");

  let submitAction;
  const theme = useTheme();

  async function getEnumGeneros() {
    try {
      var response = await adminService.getEnumsByTipoEnum({ tipo: 2 });
      if (response.status === 200) {
        setEnumGeneros(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getEnumEstratos() {
    try {
      var response = await adminService.getEnumsByTipoEnum({ tipo: 11 });
      if (response.status === 200) {
        setEnumEstratos(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getEnumCursos() {
    try {
      var response = await adminService.getEnumsByTipoEnum({ tipo: 6 });
      if (response.status === 200) {
        setEnumGrados(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getEnumGrupos() {
    try {
      var response = await adminService.getEnumsByTipoEnum({ tipo: 8 });
      if (response.status === 200) {
        setEnumGrupos(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getColegios() {
    try {
      var response = await paramsService.getSchools();
      if (response.status === 200) {
        setColegios(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getGrados(data) {
    try {
      var response = await paramsService.getGrades(data);
      if (response.status === 200) {
        setGrados(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getGrupos(data) {
    try {
      var response = await paramsService.getClassRoom(data);
      if (response.status === 200) {
        setGrupos(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getEstudiantes(data) {
    try {
      setOpenLoading(true);
      let codEstudiante = data.codEstudiante;

      var response = await paramsService.getStudents({
        codEstudiante: data.codEstudiante,
        colegio: data.colegio,
        grado: data.grado,
        curso: data.curso,
        prueba: -1
      });
      if (response.status === 200) {
        setOpenLoading(false);
        let data = response.data.data;

        getColegios();
        setEstudiantes([]);

        let arrayTemp = [];
        let colegioTemp = null;
        let gradoTemp = null;
        let grupoTemp = null;

        data.map((item) => {
          colegioTemp = item.id_ie;
          gradoTemp = item.id_curso;
          grupoTemp = item.id_grupo;

          if (filterCode === "filterCode") {
            if (item.cod_estudiante === codEstudiante) {
              item.selected = false;
              arrayTemp.push(item);
            }
          } else {
            item.selected = false;
            arrayTemp.push(item);
          }
        });

        if (arrayTemp.length > 0) {
          setColegio(colegioTemp);
          setGrado(gradoTemp);
          setGrupo(grupoTemp);
          setEstudiantes(arrayTemp);
        } else {
          showAlertInfo("No se encontraron estudiantes para asignar");
        }
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("No se encontraron estudiantes");
    }
  }
  async function estudiantesUpdate(data) {
    try {
      var response = await estudianteService.updateDatosEstudiantes(data);
      if (response.status === 200) {
        let data = response.data.data;

        let tempEstudiantes = estudiantes;
        let arrayTemp = []

        data.map((item) => {
          const estudianteExistente = tempEstudiantes.find(estudiante => estudiante.id === item.codigo);

          if (estudianteExistente) {
            estudianteExistente.selected = false
            estudianteExistente.status = item.status
          } else {
            estudianteExistente.status = false
          }
          arrayTemp.push(estudianteExistente)
        });

        setEstudiantes(arrayTemp)
        setEstudiantesToUpdate([])
        showAlertSuccess(moment().format("DD-MM-YYYY hh:mm:ss") + ": Proceso de actualización completado! Revise los comentarios en columna 'Resultado de actualización'")
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  const handleClickFilter = (event, resetForm) => {
    setFilterCode(event.target.value);
    clearForm();
    resetForm();
  };

  const addItemToEstudiantesUpdate = (data) => {
    let tempEstudiantesToUpdate = estudiantesToUpdate
    const estudianteExistente = tempEstudiantesToUpdate.find(estudiante => estudiante.estudiante === data.estudiante);

    if (estudianteExistente) {
      for (const clave in data) {
        estudianteExistente[clave] = data[clave];
      }
    } else {
      estudiantesToUpdate.push(data);
    }
    setEstudiantesToUpdate(tempEstudiantesToUpdate.filter((objeto) => { return Object.keys(objeto).length > 0; }))
  }

  const handleChange = (e, item) => {
    let tempEstudiantes = estudiantes;
    let arrayTemp = []

    tempEstudiantes.map(element => {
      let temp = {}

      if (element.cod_estudiante === item.cod_estudiante) {
        element[e.target.name] = e.target.value
        element.selected = true

        temp.estudiante = parseInt(element.id)
        temp[e.target.name] = e.target.value
      }
      addItemToEstudiantesUpdate(temp)
      arrayTemp.push(element)
    })
    setEstudiantes(arrayTemp)
  };

  const handleChangeMasivo = (e) => {
    let tempEstudiantes = estudiantes;
    let arrayTemp = [];

    tempEstudiantes.map((item) => {
      let temp = {}
      item.id_ie = colegio;
      item.id_curso = grado;
      item.id_grupo = grupo;
      item.selected = true

      temp['estudiante'] = item.id
      temp['id_ie'] = colegio;
      temp['id_curso'] = grado;
      temp['id_grupo'] = grupo;

      addItemToEstudiantesUpdate(temp)
      arrayTemp.push(item);
    })

    setEstudiantes(arrayTemp)
  }

  const handleChangeGlobal = (e) => {
    switch (e.target.name) {
      case "colegio":
        setColegio(e.target.value)
        break;

      case "grado":
        setGrado(e.target.value)
        break;

      case "grupo":
        setGrupo(e.target.value)
        break;

      default:
        break;
    }
  }

  const getColegioById = (c) => {
    const found = colegios.find((element) => element.id === c);
    return found.nombre
  }
  const getGrupoById = (c) => {
    const found = enumGrupos.find((element) => element.id === c);
    return found.name
  }
  const getGradoById = (c) => {
    const found = enumGrados.find((element) => element.id === c);
    return found.name
  }

  const initForm = () => {
    getColegios();
    getEnumGeneros();
    getEnumEstratos();
    getEnumCursos();
    getEnumGrupos();
  };
  const clearForm = () => {
    setColegios([]);
    setGrados([]);
    setGrupos([]);
    setEstudiantes([]);
    initForm();
  };
  useEffect(() => {
    initForm();
  }, []);

  return (
    <>

      {/* Estudiantes */}
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        validate={(values) => {
          const errors = {};
          if (filterCode === "filterMany") {
            if (values.colegio) {
              let data = {
                colegio: values.colegio,
              };
              getGrados(data);
            }
            if (values.grado) {
              let data = {
                colegio: values.colegio,
                grado: values.grado,
              };
              getGrupos(data);
            }
          }
          if (filterCode === "filterCode" && !values.codEstudiante) {
            errors.codEstudiante = "Campo Requerido";
          } else if (filterCode === "filterMany") {
            if (!values.colegio) {
              errors.colegio = "Campo Requerido";
            }
            if (!values.grado) {
              errors.grado = "Campo Requerido";
            }
            if (!values.grupo) {
              errors.grupo = "Campo Requerido";
            }
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (submitAction === "clean") {
            setEstudiantes([]);
            setEstudiantesToUpdate([]);
          }
          else if (submitAction === "buscar") {
            if (filterCode === "filterCode") {
              getEstudiantes({
                codEstudiante: values.codEstudiante,
              });
            } else {
              getEstudiantes({
                colegio: values.colegio,
                grado: values.grado,
                curso: values.grupo,
              });
            }
          } else {
            if (estudiantesToUpdate.length > 0) {
              let data_estudiantes = {
                estudiantes: estudiantesToUpdate,
              };
              estudiantesUpdate(data_estudiantes)
            } else {
              showAlertWarning("No se han registrados cambios.")
            }

          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue
          /* and other goodies */
        }) => (
          <Box
            component="form"
            noValidate
            id="asignar-pruebas-form"
            sx={{ mt: 1 }}
          >
            {/* Toolbar */}
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                //background: theme.palette.secondary.main,
                background: theme.palette.secondary.light,
                padding: "0,5em",
                minHeight: "3em",
                //pt: "3em"
              }}
            >
              {/* Habilitar */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none", height: "3em" }}
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    submitAction = "habilitar";
                    handleSubmit();
                  }}
                >
                  Actualizar datos
                </Button>
              </Grid>
              {/* Buscar */}
              {estudiantes.length === 0 && (
                <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ textTransform: "none", height: "3em" }}
                    startIcon={<SearchTwoToneIcon />}
                    onClick={() => {
                      submitAction = "buscar";
                      handleSubmit();
                    }}
                  >
                    Buscar
                  </Button>
                </Grid>
              )}
              {/* Limpíar */}
              {estudiantes.length > 0 && (
                <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ textTransform: "none", height: "3em" }}
                    startIcon={<TuneIcon />}
                    onClick={() => {
                      submitAction = "clean";
                      handleSubmit();
                    }}
                  >
                    Nueva búsqueda
                  </Button>
                </Grid>
              )}

            </Grid>
            {/* Form */}
            {estudiantes.length === 0 && (
              <div>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    bgcolor: "background.paper",
                    borderRadius: 1,
                    padding: "1em",
                  }}
                >
                  {/* Radio */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    sx={{ padding: "1em 1em 1em 1em" }}
                  >
                    <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Buscar por...
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={filterCode}
                        onChange={(event) => handleClickFilter(event, resetForm)}
                      >
                        <FormControlLabel
                          value="filterCode"
                          control={<Radio />}
                          label="Código Estudiante"
                        />
                        <FormControlLabel
                          value="filterMany"
                          control={<Radio />}
                          label="A Varios Estudiantes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {filterCode === "filterCode" ? (
                    <>
                      {/* CodEstudiante */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        sx={{ padding: "1em" }}
                      >
                        <FormControl
                          fullWidth
                          error={errors.codEstudiante ? true : false}
                        >
                          <TextField
                            error={errors.codEstudiante ? true : false}
                            required
                            autoFocus
                            label="Código estudiante"
                            name="codEstudiante"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            values={values.codEstudiante || ""}
                          />
                          {errors.codEstudiante && touched.codEstudiante && (
                            <FormHelperText>{errors.codEstudiante}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    <>
                      {/* Colegios */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        sx={{ padding: "1em" }}
                      >
                        <SelectAutocomplete
                          name="colegio"
                          label="Colegios*"
                          onOpen={getColegios}
                          setFieldValue={setFieldValue}
                          options={colegios}
                          error={errors.colegio}
                          touched={String(touched.colegio)}
                          id="id"
                          desc="nombre"
                        />
                      </Grid>
                      {/* Grados */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={2}
                        sx={{ padding: "1em" }}
                      >
                        <FormControl fullWidth error={errors.grado ? true : false}>
                          <InputLabel id="input-label-grado">Grados*</InputLabel>
                          <SelectCustom
                            labelId="select-label-grado"
                            id="select-grado"
                            name="grado"
                            label="grados*"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.grado}
                          >
                            {grados &&
                              grados.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.nombre}
                                </MenuItem>
                              ))}
                          </SelectCustom>
                          {errors.grado && touched.grado && (
                            <FormHelperText>{errors.grado}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      {/* Grupos */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={2}
                        sx={{ padding: "1em" }}
                      >
                        <FormControl fullWidth error={errors.grupo ? true : false}>
                          <InputLabel id="input-label-inst">Grupos*</InputLabel>
                          <SelectCustom
                            labelId="select-label-grupo"
                            id="select-grupo"
                            name="grupo"
                            label="grupos*"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.grupo}
                          >
                            {grupos &&
                              grupos.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.nombre}
                                </MenuItem>
                              ))}
                          </SelectCustom>
                          {errors.grupo && touched.grupo && (
                            <FormHelperText>{errors.grupo}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            )}
          </Box>
        )}
      </Formik >
      {
        estudiantes.length > 0 && (
          <>
            <Divider />
            <Grid item sm={11} md={11} sx={{ pt: "0.5em", pl: "3em" }}>
              <Typography variant="h6" align="left" color="text.primary">
                Listado de estudiantes / Registros: {estudiantes.length}
              </Typography>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2} sx={{ pt: "1em", pl: "3em" }}
            >
              <Grid item xs="auto">
                <SelectCustom
                  labelId="select-label-id_ie"
                  id="select-id_ie"
                  name="colegio"
                  label="Colegio"
                  onChange={(e) => handleChangeGlobal(e)}
                  value={colegio}
                >
                  {colegios &&
                    colegios.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.nombre}
                      </MenuItem>
                    ))}
                </SelectCustom>
              </Grid>

              <Grid item xs="auto">
                <SelectCustom
                  labelId="select-label-id_curso"
                  id="select-id_curso"
                  name="grado"
                  label="Grado"
                  onChange={(e) => handleChangeGlobal(e)}
                  value={grado}
                >
                  {enumGrados &&
                    enumGrados.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectCustom>
              </Grid>

              <Grid item xs="auto">
                <SelectCustom
                  labelId="select-label-id_grupo"
                  id="select-id_grupo"
                  name="grupo"
                  label="Grupo"
                  onChange={(e) => handleChangeGlobal(e)}
                  value={grupo}
                >
                  {enumGrupos &&
                    enumGrupos.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectCustom>
              </Grid>

              <Grid item xs="auto">
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none", height: "3em" }}
                  onClick={handleChangeMasivo}
                >
                  Aplicar a todos
                </Button>
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} sx={{ padding: "1em" }}>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width={"5%"} sx={{ pl: "15px" }}></StyledTableCell>
                      <StyledTableCell width={"10%"}>Colegio / Grado / Grupo</StyledTableCell>
                      <StyledTableCell width={"5%"}>Código</StyledTableCell>
                      <StyledTableCell width={"7%"}>Primer nombre</StyledTableCell>
                      <StyledTableCell width={"7%"}>Segundo nombre</StyledTableCell>
                      <StyledTableCell width={"7%"}>Primer apellido</StyledTableCell>
                      <StyledTableCell width={"7%"}>Segundo apellido</StyledTableCell>
                      <StyledTableCell width={"10%"}>Genero</StyledTableCell>
                      <StyledTableCell width={"10%"}>Fec. de Nacimiento</StyledTableCell>
                      <StyledTableCell width={"10%"}>Estrato</StyledTableCell>
                      <StyledTableCell width={"10%"}>Resultado actualización</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {estudiantes.map((item, index) => (
                      <StyledTableRow key={item.id}>
                        <StyledTableCell component="th" scope="row">
                          <Checkbox
                            disabled
                            id={String(item.id)}
                            color="primary"
                            checked={item.selected}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {getColegioById(item.id_ie)} / {getGradoById(item.id_curso)} / {getGrupoById(item.id_grupo)}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {item.cod_estudiante}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {
                            <TextField
                              id={String(item.id)}
                              name="pri_nom"
                              variant="standard"
                              onChange={(e) => handleChange(e, item)}
                              value={item.pri_nom || ''}
                            />
                          }
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {
                            <TextField
                              id={String(item.id)}
                              name="seg_nom"
                              variant="standard"
                              onChange={(e) => handleChange(e, item)}
                              value={item.seg_nom || ''}
                            />
                          }
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <TextField
                            id={String(item.id)}
                            name="pri_ape"
                            variant="standard"
                            onChange={(e) => handleChange(e, item)}
                            value={item.pri_ape || ''}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <TextField
                            id={String(item.id)}
                            name="seg_ape"
                            variant="standard"
                            onChange={(e) => handleChange(e, item)}
                            value={item.seg_ape || ''}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <SelectCustom
                            labelId="select-label-sexo"
                            id="select-sexo"
                            name="sexo"
                            onChange={(e) => handleChange(e, item)}
                            onBlur={(e) => handleChange(e, item)}
                            value={item.sexo || ''}
                          >
                            {enumGeneros &&
                              enumGeneros.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name ?? ''}
                                </MenuItem>
                              ))}
                          </SelectCustom>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <DateTimer
                            name="fec_nac"
                            setValueTemp={setValueTemp}
                            onChange={(e) => handleChange(e, item)}
                            value={item.fec_nac}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <SelectCustom
                            labelId="select-label-estrato"
                            id="select-estrato"
                            name="estrato"
                            onChange={(e) => handleChange(e, item)}
                            onBlur={(e) => handleChange(e, item)}
                            value={item.estrato || ''}
                          >
                            {enumEstratos &&
                              enumEstratos.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </SelectCustom>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {item.status === true ? (
                            <Tooltip title="Actualización realizada correctamente.">
                              <IconButton>
                                <CheckBoxIcon
                                  fontSize="large"
                                  color="success" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            item.status === false ?
                              <Tooltip title="Actualización falló.">
                                <IconButton>
                                  <ErrorIcon
                                    fontSize="large"
                                    color="danger" />
                                </IconButton>
                              </Tooltip>
                              : <Tooltip title="No hay registro de actualización.">
                                <IconButton>
                                  <HelpCenterIcon
                                    fontSize="large"
                                    color="disabled" />
                                </IconButton>
                              </Tooltip>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )
      }
    </>
  );
}

export default UpdateEstudianteForm;
