const getColegios = (data) => {
  let jsonResult = data;  
  jsonResult.codigo = data.id || "";
  jsonResult.nombre = data.nombre || "";
  jsonResult.direccion = data.direccion || "";
  jsonResult.celular = data.celular || "";
  jsonResult.telefono = data.telefono || "";
  jsonResult.email = data.email || "";
  jsonResult.id_departamento = data.id_departamento || "";
  jsonResult.codigo_dane = data.codigo_dane || "";  
  return jsonResult;
};

const colegiosAdapters = {  
  getColegios,
};

export default colegiosAdapters;