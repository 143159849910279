import moment from 'moment';

export const TabTittle = (titulo) => {
  return (document.tittle = titulo);
};

export const concatenar = (params) => {
  var cadena = "";
  params.forEach((element) => {
    cadena += !element ? "" : element + " ";
  });
  return cadena;
};

export const validatePhone =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const columnsDGrid = (titulos, rows) => {
  let columns = [];
  // eslint-disable-next-line array-callback-return
  titulos.map((item) => {
    let wd = 0;
    let keyJson = rows[item] + "";
    let keyColumn = item + "";
    let lgLetter = 0;
    if (keyJson.length > keyColumn.length) {
      lgLetter = keyJson.length;
    } else {
      lgLetter = keyColumn.length;
    }
    switch (true) {
      case lgLetter < 10:
        wd = 100;
        break;
      case lgLetter > 10 && lgLetter <= 30:
        wd = 200;
        break;
      default:
        wd = lgLetter * 7;
        break;
    }
    let jsonTitulos = {
      field: item,
      headerName: item,
      //description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: wd,
    };
    columns.push(jsonTitulos);
  });
  return columns;
};

export const formatDate = (fecha) => {
  return moment(fecha).format('YYYY-MM-DD');
};