import React, { useState, useEffect } from "react";
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import ButtonIcon from "components/ui/Buttons/ButtonIcon";
import TextFieldCustom from "components/ui/TextFields/TextFieldCustom";
import QuickSearch from "components/QuickSearch";
import { showAlert, showAlertError, showAlertSuccess } from "utils/alert";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import paramsService from "services/params.services";
import adminService from "services/admin.services";
import TextFieldDate from "components/ui/TextFields/TextFieldDate";
import { formatDate } from "utils/functions";
import ButtonBorder from "components/ui/Buttons/ButtonBorder";

const Recursos = (props) => {
  const { handleChange, handleBlur, values, errors, touched, tiposRecursos } = props;
  return (
    <>
      <Typography variant="h6" color={"primary"} gutterBottom>
        Recursos
      </Typography>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          borderRadius: 1,
        }}
      >
        {/* Tipo de Recurso */}
        <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
          <QuickSearch
            name="tipoRecurso"
            label="Tipos Recursos"
            keyItem="id"
            valueItem="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.tipoRecurso || ""}
            error={errors.tipoRecurso}
            touched={touched.tipoRecurso}
            isQuickSearch={false}
            data={tiposRecursos}
          />
        </Grid>

        {/* Detalle de Recurso */}
        <Grid item xs={12} sm={12} md={6} lg={8} sx={{ padding: "1em" }}>
          <TextFieldCustom
            name="dataRecurso"
            label="Detalle Recurso"
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.dataRecurso}
            value={values.dataRecurso || ""}
            error={errors.dataRecurso}
          />
        </Grid>
      </Grid>
    </>
  );
}

const Preguntas = (props) => {
  const { tiposRecursos, valuesPadre, isAdd, setOpenLoading } = props;
  const [recursos, setRecursos] = useState([]);
  const [editIndex, setEditIndex] = useState(null); // Estado para el índice del recurso en edición
  const [editResponseIndex, setEditResponseIndex] = useState(null); // Estado para editar respuestas

  const initialValues = {
    prueba: "",
    pregunta: "",
    nombre: "",
    tipo: "",
    archivo: "",
    jsSeccion: "",
    consecutivo: "",
  };

  useEffect(() => {
    if (valuesPadre.preguntas && valuesPadre.preguntas.length > 0) {
      setRecursos(valuesPadre.preguntas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agregarRecurso = (values) => {
    setRecursos([...recursos, { ...values, respuestas: [] }]);
  };

  const editarRecurso = (index) => {
    setEditIndex(index);
  };

  const eliminarRecurso = async (index) => {
    const recursoFiltrado = recursos.filter((_, i) => i == index);
    const recursoJSON = recursoFiltrado.length > 0 ? recursoFiltrado[0] : null;
    let eliminar = true;
    if (!isAdd) {
      eliminar = await eliminarPregunta(recursoJSON.prueba, recursoJSON.pregunta);
    }
    if (eliminar) {
      setRecursos(recursos.filter((_, i) => i !== index));
      valuesPadre.preguntas = recursos.filter((_, i) => i !== index);
    }
  };

  const guardarRecurso = async (values) => {
    let agregar = false;
    const nuevosRecursos = [...recursos];
    if (editIndex !== null) {
      agregar = await actualizarPregunta(values, nuevosRecursos[editIndex]);
      if (agregar) {
        nuevosRecursos[editIndex] = { ...values, respuestas: nuevosRecursos[editIndex].respuestas };
      }
    } else {
      agregar = await crearPregunta(values);
      if (agregar) {
        nuevosRecursos.push({ ...values, respuestas: [] });
      }
    }
    setRecursos(nuevosRecursos);
    valuesPadre.preguntas = nuevosRecursos;
    setEditIndex(null); // Restablece el índice de edición
  };

  const guardarRespuestas = async (values, index) => {
    const nuevosRecursos = [...recursos];
    await values.respuestas.forEach(async (respuesta) => {
      const pregunta = nuevosRecursos[index];
      // const agregar = false;
      if (!respuesta.respuesta) {
        respuesta.prueba = pregunta.prueba;
        respuesta.pregunta = pregunta.pregunta;
        await crearRespuesta(respuesta);
      } else {
        await actualizarRespuesta(respuesta);
      }
    });
    console.log(values.respuestas);
    nuevosRecursos[index].respuestas = values.respuestas; // Actualizar las respuestas del recurso
    setRecursos(nuevosRecursos);
    valuesPadre.preguntas = nuevosRecursos;
    setEditResponseIndex(null); // Restablece el índice de edición de respuestas
  };

  const eliminarRespuestaData = async (index, data, remove) => {
    const recursoFiltrado = data.filter((_, i) => i == index);
    const recursoJSON = recursoFiltrado.length > 0 ? recursoFiltrado[0] : null;
    let eliminar = true;
    if (!isAdd) {
      eliminar = await eliminarRespuesta(recursoJSON.prueba, recursoJSON.pregunta, recursoJSON.respuesta);
    }
    if (eliminar) {
      remove(index);
    }
  };

  async function crearPregunta(data) {
    try {
      setOpenLoading(true);
      let dataUpdate = { ...data };
      dataUpdate.prueba = valuesPadre.prueba;
      delete dataUpdate.respuestas;
      //seccion
      if (dataUpdate.jsSeccion) {
        dataUpdate.seccion = {
          consecutivo: "1",
          nombre: dataUpdate.jsSeccion
        }
        delete dataUpdate.jsSeccion;
      }
      delete dataUpdate.archivo;
      var response = await adminService.crearPregunta(dataUpdate);
      if (response.status === 200) {
        setOpenLoading(false);
        data.pregunta = response.data.data.id;
        data.prueba = valuesPadre.prueba;
        return true;
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al actualizar la pregunta');
      setOpenLoading(false);
      return false;
    }
  }

  async function actualizarPregunta(values, data) {
    try {
      setOpenLoading(true);
      let dataUpdate = { ...data };
      delete dataUpdate.respuestas;
      //seccion
      if (dataUpdate.jsSeccion) {
        dataUpdate.seccion = {
          consecutivo: "1",
          nombre: dataUpdate.jsSeccion
        }
        delete dataUpdate.jsSeccion;
      }
      delete dataUpdate.archivo;
      var response = await adminService.actualizarPregunta(dataUpdate);
      if (response.status === 200) {
        setOpenLoading(false);
        values.pregunta = response.data.data.id;
        values.prueba = valuesPadre.prueba;
        return true;
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al actualizar la pregunta');
      setOpenLoading(false);
      return false;
    }
  }

  async function eliminarPregunta(prueba, pregunta) {
    try {
      setOpenLoading(true);
      console.log(prueba, pregunta);
      var response = await adminService.eliminarPregunta(prueba, pregunta);
      if (response.status === 200) {
        setOpenLoading(false);
        return true;
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al eliminar la pregunta');
      setOpenLoading(false);
      return false;
    }
  }

  async function crearRespuesta(data) {
    try {
      setOpenLoading(true);
      let dataUpdate = { ...data };
      dataUpdate.prueba = valuesPadre.prueba;
      delete dataUpdate.archivo;
      var response = await adminService.crearRespuesta(dataUpdate);
      if (response.status === 200) {
        setOpenLoading(false);
        // data.pregunta = response.data.data.id;
        // data.prueba = valuesPadre.prueba;
        return true;
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al actualizar la pregunta');
      setOpenLoading(false);
      return false;
    }
  }

  async function actualizarRespuesta(data) {
    try {
      setOpenLoading(true);
      let dataUpdate = { ...data };
      // dataUpdate.prueba = valuesPadre.prueba;
      delete dataUpdate.archivo;
      var response = await adminService.actualizarRespuesta(dataUpdate);
      if (response.status === 200) {
        setOpenLoading(false);
        // data.pregunta = response.data.data.id;
        // data.prueba = valuesPadre.prueba;
        return true;
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al actualizar la pregunta');
      setOpenLoading(false);
      return false;
    }
  }

  async function eliminarRespuesta(prueba, pregunta, respuesta) {
    try {
      setOpenLoading(true);
      var response = await adminService.eliminarRespuesta(prueba, pregunta, respuesta);
      if (response.status === 200) {
        setOpenLoading(false);
        return true;
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al eliminar la respuesta');
      setOpenLoading(false);
      return false;
    }
  }

  return (
    <>
      <Formik
        initialValues={editIndex !== null ? recursos[editIndex] : initialValues}
        enableReinitialize // Asegura que el formulario se reinicie cuando cambie editIndex
        onSubmit={async (values, { resetForm }) => {
          await guardarRecurso(values);
          resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <>
            <Typography variant="h6" color="primary" gutterBottom>
              Preguntas
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <TextFieldCustom
                  name="nombre"
                  label="Nombre"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nombre || ""}
                  error={errors.nombre}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <QuickSearch
                  name="tipo"
                  label="Tipos Recursos"
                  keyItem="id"
                  valueItem="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tipo || ""}
                  error={errors.tipo}
                  touched={touched.tipo}
                  isQuickSearch={false}
                  data={tiposRecursos}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextFieldCustom
                  name="archivo"
                  label="Archivo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.archivo || ""}
                  error={errors.archivo}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextFieldCustom
                  name="jsSeccion"
                  label="Sección"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.jsSeccion || ""}
                  error={errors.jsSeccion}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextFieldCustom
                  name="consecutivo"
                  label="Consecutivo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.consecutivo || ""}
                  error={errors.consecutivo}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={2}>
                <ButtonBorder
                  id="btnSubmit"
                  fullWidth
                  iconname={editIndex !== null ? "save" : "add"}
                  description={editIndex !== null ? "Actualizar" : "Agregar"}
                  sx={{ mt: "0.5em" }}
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Formik>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {recursos.map((recurso, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ padding: "1em" }}>
              <CardHeader
                title={`# ${index + 1}`}
                action={
                  <Box sx={{ display: 'flex', gap: 1 }}> {/* Usando gap para el espacio entre botones */}
                    <ButtonIcon
                      iconname="edit"
                      description="Editar"
                      color="primary"
                      onClick={() => editarRecurso(index)}
                      variant="outlined"
                    />
                    <ButtonIcon
                      iconname="delete"
                      description="Eliminar"
                      color="error"
                      onClick={() => eliminarRecurso(index)}
                      variant="outlined"
                    />
                  </Box>
                }
              />
              <CardContent>
                <Typography variant="body2"><strong>Pregunta:</strong> {recurso.pregunta}</Typography>
                <Typography variant="body2"><strong>Nombre:</strong> {recurso.nombre}</Typography>
                <Typography variant="body2"><strong>Tipo:</strong> {recurso.tipo}</Typography>
                <Typography variant="body2"><strong>Archivo:</strong> {recurso.archivo}</Typography>
                {/* <Typography variant="body2">Sección: {recurso.seccion || "N/A"}</Typography> */}
                {/* <Typography variant="body2">Consecutivo: {recurso.consecutivo}</Typography> */}

                <Typography variant="h6" gutterBottom>Respuestas</Typography>
                {editResponseIndex === index ? (
                  <Formik
                    initialValues={{ respuestas: recurso.respuestas || [] }} // Cargar respuestas del recurso
                    enableReinitialize // Asegura que el formulario se reinicie cuando cambie el recurso
                    onSubmit={(values) => {
                      guardarRespuestas(values, index);
                    }}
                  >
                    {({ values, handleSubmit, handleChange, handleBlur }) => (
                      <>
                        <FieldArray
                          name="respuestas"
                          render={({ push, remove }) => (
                            <>
                              {values.respuestas.map((respuesta, i) => (
                                <Grid container key={i} alignItems="center" spacing={2}>
                                  <Grid item xs={12} sx={{ padding: '8px' }}>
                                    <TextFieldCustom
                                      name={`respuestas.${i}.descripcion`}
                                      label="Descripción"
                                      onChange={handleChange}
                                      value={respuesta.descripcion || ""}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sx={{ padding: '8px' }}>
                                    <TextFieldCustom
                                      name={`respuestas.${i}.validacion`}
                                      label="Validación"
                                      onChange={handleChange}
                                      value={respuesta.validacion || ""}
                                      type="number"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sx={{ padding: '8px' }}>
                                    {/* <TextFieldCustom
                                      name={`respuestas.${i}.tipo`}
                                      label="Tipo"
                                      onChange={handleChange}
                                      value={respuesta.tipo || ""}
                                      type="number"
                                    /> */}
                                    <QuickSearch
                                      name={`respuestas.${i}.tipo`}
                                      label="Tipos Recursos"
                                      keyItem="id"
                                      valueItem="name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={respuesta.tipo || ""}
                                      // error={respuesta.tipo}
                                      touched={respuesta.tipo}
                                      isQuickSearch={false}
                                      data={tiposRecursos}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sx={{ padding: '8px' }}>
                                    <TextFieldCustom
                                      name={`respuestas.${i}.archivo`}
                                      label="Archivo (Base64)"
                                      onChange={handleChange}
                                      value={respuesta.archivo || ""}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sx={{ padding: '8px' }}>
                                    <ButtonIcon
                                      iconname="delete"
                                      description="Eliminar"
                                      color="error"
                                      onClick={() => eliminarRespuestaData(i, values.respuestas, remove)}
                                    />
                                  </Grid>
                                </Grid>

                              ))}
                              <Grid item xs={12}>
                                <ButtonBorder
                                  id="btnAddResponse"
                                  fullWidth
                                  iconname="add"
                                  description="Agregar Respuesta"
                                  onClick={() => push({ prueba: "", pregunta: "", descripcion: "", validacion: "", tipo: "", archivo: "" })}
                                />
                              </Grid>
                            </>
                          )}
                        />
                        <ButtonBorder
                          id="btnSaveResponses"
                          fullWidth
                          iconname="save"
                          description="Guardar Respuestas"
                          onClick={handleSubmit}
                        />
                      </>
                    )}
                  </Formik>
                ) : (
                  <>
                    <ButtonBorder
                      id="btnEditResponses"
                      fullWidth
                      iconname="edit"
                      description="Editar Respuestas"
                      onClick={() => setEditResponseIndex(index)}
                    />
                    <ul>
                      {recurso.respuestas.map((respuesta, i) => (
                        <li key={i}>
                          {respuesta.respuesta}: {respuesta.descripcion} (Tipo: {respuesta.tipo})
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const initialValues = {
  prueba: "",
  nombre: "",
  tipo: 35,
  tiempo: -1,
  instruciones: "", //opcional 
  tipoRecurso: "",
  dataRecurso: "",
  preguntas: []
  // "recurso" : {
  //   "tipo" : "12",
  //   "data": "prueba"
  // }
};
const formSchema = Yup.object().shape({
  // t_doc: Yup.number().required("Campo Requerido"),
  // num_doc: Yup.string().required("Campo Requerido"),
  // pri_nom: Yup.string().required("Campo Requerido"),
  // pri_ape: Yup.string().required("Campo Requerido"),
  // sexo: Yup.number().required("Campo Requerido"),
  // fec_nac: Yup.string().required("Campo Requerido"),
  // nivel_educ: Yup.number().required("Campo Requerido"),
  // estrato: Yup.number().required("Campo Requerido"),
  // correo: Yup.string().email("Correo electrónico no válido").required("Campo Requerido"),
  // num_cel: Yup.string().required("Campo Requerido"),
  // direc: Yup.string().required("Campo Requerido"),
  // id_localidad: Yup.number().required("Campo Requerido"),
});

const PruebaForm = ({ setOpenLoading, data, isAdd, setMostrarRI, initPadre }) => {
  const [tiposPruebas, setTiposPruebas] = useState([]);
  const [tiposRecursos, setTiposRecursos] = useState([]);
  const clearForm = () => {
    //setSavedData(null);
  };
  async function getTipoPruebas() {
    try {
      setOpenLoading(true);
      const data = {
        tipo: 7
      }
      var response = await adminService.getEnumsByTipoEnum(data);
      if (response.status === 200) {
        setOpenLoading(false);
        setTiposPruebas(response.data.data);
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');
      setOpenLoading(true);
    }
  }
  async function getTipoRecursos() {
    try {
      setOpenLoading(true);
      const data = {
        tipo: 5
      }
      var response = await adminService.getEnumsByTipoEnum(data);
      if (response.status === 200) {
        setOpenLoading(false);
        setTiposRecursos(response.data.data);
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');
      setOpenLoading(true);
    }
  }
  async function saveParticipante(data) {
    var response = null;
    if (isAdd) {
      response = await paramsService.crearParticipante(data);
    } else {
      response = await paramsService.actualizarParticipante(data);
    }
  }
  const init = () => {
    getTipoPruebas();
    getTipoRecursos();
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Formik
        enableReinitialize={data}
        initialValues={data || initialValues}
        validate={(values) => {
          const errors = {};
          // if (values.id_departamento) getMunicipios(values.id_departamento);
          return errors;
        }}
        validationSchema={formSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log(isAdd);
          console.log(values);
          // setOpenLoading(true);
          // values.id_rol = rol;
          // values.fec_nac = formatDate(values.fec_nac);
          // saveParticipante(values)
          //   .then(() => {
          //     setOpenLoading(false);
          //     showAlertSuccess("Participante grabado correctamente");
          //     resetForm();
          //     setMostrarRI(false);
          //     initPadre();
          //   })
          //   .catch((error) => {
          //     let mensaje = "";
          //     if (error.response.data != null && error.response.data.mensaje) {
          //       mensaje = error.response.data.mensaje;
          //     } else {
          //       mensaje = 'Error al crear el participante';
          //     }
          //     setOpenLoading(false);
          //     showAlertError(mensaje);
          //   });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue,
          isLoading,
          /* and other goodies */
        }) => (
          <Box component="form-Login" noValidate sx={{ mt: 1 }}>
            {/* Toolbar */}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: "background.paper",
              }}
            >
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <ButtonIcon
                  id="btnSubmit"
                  fullWidth
                  iconname="cleaning_services"
                  description="Limpiar"
                  sx={{ mt: "1.5em" }}
                  color="primary"
                  onClick={() => {
                    resetForm();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <ButtonIcon
                  id="btnSubmit"
                  fullWidth
                  iconname="save"
                  description="Guardar"
                  sx={{ mt: "1.5em" }}
                  color="success"
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
            {/* Page*/}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                borderRadius: 1,
              }}
            >
              {/* prueba */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  autoFocus={true}
                  disabled={data}
                  name="prueba"
                  label="Código"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.prueba}
                  value={values.prueba || ""}
                  error={errors.prueba}
                />
              </Grid>
              {/* nombre */}
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="nombre"
                  label="Nombre"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.nombre}
                  value={values.nombre || ""}
                  error={errors.nombre}
                />
              </Grid>
              {/* tipo */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{ padding: "1em" }}
              >
                <QuickSearch
                  name="tipo"
                  label="Tipos Pruebas"
                  keyItem="id"
                  valueItem="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tipo || ""}
                  error={errors.tipo}
                  touched={touched.tipo}
                  isQuickSearch={false}
                  data={tiposPruebas}
                />
              </Grid>
              {/* tiempo */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="tiempo"
                  label="Tiempo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.tiempo}
                  value={values.tiempo || ""}
                  error={errors.tiempo}
                  type="number"
                />
              </Grid>
              {/* instruciones */}
              <Grid item xs={12} sm={12} md={6} lg={8} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="instruciones"
                  label="Instrucciones"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.instruciones}
                  value={values.instruciones || ""}
                  error={errors.instruciones}
                />
              </Grid>
              {/* Recursos */}
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // gap: 2,
                  padding: "1em",
                  border: "1px solid #e0e0e0",
                  borderRadius: 1,
                  marginBottom: "1em"
                }}
              >
                <Recursos
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  tiposRecursos={tiposRecursos}
                />
              </Grid>
              {/* Preguntas */}
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // gap: 2,
                  padding: "1em",
                  border: "1px solid #e0e0e0",
                  borderRadius: 1,
                  marginBottom: "1em"
                }}
              >
                <Preguntas
                  tiposRecursos={tiposRecursos}
                  valuesPadre={values}
                  isAdd={isAdd}
                  setOpenLoading={setOpenLoading}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default PruebaForm;
