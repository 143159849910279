import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import ButtonIcon from "components/ui/Buttons/ButtonIcon";
import TextFieldCustom from "components/ui/TextFields/TextFieldCustom";
import QuickSearch from "components/QuickSearch";
import { showAlert, showAlertError, showAlertSuccess } from "utils/alert";
import * as Yup from "yup";
import { Formik } from "formik";
import paramsService from "services/params.services";
import adminService from "services/admin.services";
import TextFieldDate from "components/ui/TextFields/TextFieldDate";
import { formatDate } from "utils/functions";

const initialValues = {
  num_doc: "",
  pri_nom: "",
  seg_nom: "",
  pri_ape: "",
  seg_ape: "",
  sexo: "",
  fec_nac: "",
  nivel_educ: "",
  edad_meses: "",
  estrato: "",
  correo: "",
  num_cel: "",
  direc: "",
  id_localidad: "",
  id_departamento: "",
  id_rol: ""
};
const formSchema = Yup.object().shape({
  t_doc: Yup.number().required("Campo Requerido"),
  num_doc: Yup.string().required("Campo Requerido"),
  pri_nom: Yup.string().required("Campo Requerido"),
  // seg_nom: Yup.string().required("Campo Requerido"),
  pri_ape: Yup.string().required("Campo Requerido"),
  // seg_ape: Yup.string().required("Campo Requerido"),
  sexo: Yup.number().required("Campo Requerido"),
  fec_nac: Yup.string().required("Campo Requerido"),
  nivel_educ: Yup.number().required("Campo Requerido"),
  estrato: Yup.number().required("Campo Requerido"),
  correo: Yup.string().email("Correo electrónico no válido").required("Campo Requerido"),
  num_cel: Yup.string().required("Campo Requerido"),
  direc: Yup.string().required("Campo Requerido"),
  id_localidad: Yup.number().required("Campo Requerido"),
  // id_rol: Yup.number().required("Campo Requerido")
});
const ParticipanteForm = ({ setOpenLoading, data, isAdd, setMostrarRI, initPadre, rol }) => {
  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [tiposDocumumentos, setTipoDocumentos] = useState([]);
  const [sexos, setSexos] = useState([]);
  const [nivelesEducativos, setNivelesEducativo] = useState([]);
  const [estratos, setEstratos] = useState([]);
  const [roles, setRoles] = useState([]);
  const clearForm = () => {
    //setSavedData(null);
  };
  async function getDepartaments() {
    try {
      setOpenLoading(true);
      var response = await paramsService.getDepartaments();
      if (response.status === 200) {
        setOpenLoading(false);
        setDepartamentos(response.data.data);
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');
      setOpenLoading(true);
    }
  }
  const getMunicipios = async (departamento) => {
    let pruebas = departamentos.find((e) => e.id === departamento).municipios;
    setMunicipios(pruebas);
  };
  async function getTipoDocumentos() {
    try {
      setOpenLoading(true);
      const data = {
        tipo: 1
      }
      var response = await adminService.getEnumsByTipoEnum(data);
      if (response.status === 200) {
        setOpenLoading(false);
        setTipoDocumentos(response.data.data);
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');
      setOpenLoading(true);
    }
  }
  async function getSexos() {
    try {
      setOpenLoading(true);
      const data = {
        tipo: 2
      }
      var response = await adminService.getEnumsByTipoEnum(data);
      if (response.status === 200) {
        setOpenLoading(false);
        setSexos(response.data.data);
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');
      setOpenLoading(true);
    }
  }
  async function getNivelesEducativos() {
    try {
      setOpenLoading(true);
      const data = {
        tipo: 12
      }
      var response = await adminService.getEnumsByTipoEnum(data);
      if (response.status === 200) {
        setOpenLoading(false);
        setNivelesEducativo(response.data.data);
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');
      setOpenLoading(true);
    }
  }
  async function getEstratos() {
    try {
      setOpenLoading(true);
      const data = {
        tipo: 11
      }
      var response = await adminService.getEnumsByTipoEnum(data);
      if (response.status === 200) {
        setOpenLoading(false);
        setEstratos(response.data.data);
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');
      setOpenLoading(true);
    }
  }
  async function saveParticipante(data) {
    var response = null;
    if (isAdd) {
      response = await paramsService.crearParticipante(data);
    } else {
      response = await paramsService.actualizarParticipante(data);
    }
  }
  const init = () => {
    getDepartaments();
    getTipoDocumentos();
    getSexos();
    getNivelesEducativos();
    getEstratos();
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Formik
        enableReinitialize={data}
        initialValues={data || initialValues}
        validate={(values) => {
          const errors = {};
          if (values.id_departamento) getMunicipios(values.id_departamento);
          return errors;
        }}
        validationSchema={formSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setOpenLoading(true);
          values.id_rol = rol;
          values.fec_nac = formatDate(values.fec_nac);
          saveParticipante(values)
            .then(() => {
              setOpenLoading(false);
              showAlertSuccess("Participante grabado correctamente");
              resetForm();
              setMostrarRI(false);
              initPadre();
            })
            .catch((error) => {
              let mensaje = "";
              if (error.response.data != null && error.response.data.mensaje) {
                mensaje = error.response.data.mensaje;
              } else {
                mensaje = 'Error al crear el participante';
              }
              setOpenLoading(false);
              showAlertError(mensaje);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue,
          isLoading,
          /* and other goodies */
        }) => (
          <Box component="form-Login" noValidate sx={{ mt: 1 }}>
            {/* Toolbar */}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: "background.paper",
              }}
            >
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <ButtonIcon
                  id="btnSubmit"
                  fullWidth
                  iconname="cleaning_services"
                  description="Limpiar"
                  sx={{ mt: "1.5em" }}
                  color="primary"
                  onClick={() => {
                    resetForm();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <ButtonIcon
                  id="btnSubmit"
                  fullWidth
                  iconname="save"
                  description="Guardar"
                  sx={{ mt: "1.5em" }}
                  color="success"
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
            {/* Page*/}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                borderRadius: 1,
              }}
            >
              {/* t_doc */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{ padding: "1em" }}
              >
                <QuickSearch
                  name="t_doc"
                  label="Tipos de Documentos"
                  keyItem="id"
                  valueItem="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.t_doc}
                  error={errors.t_doc}
                  touched={touched.t_doc}
                  isQuickSearch={false}
                  data={tiposDocumumentos}
                />
              </Grid>
              {/* num_doc */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  autoFocus={true}
                  disabled={data}
                  name="num_doc"
                  label="Número Documento"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.num_doc}
                  value={values.num_doc || ""}
                  error={errors.num_doc}
                />
              </Grid>
              {/* pri_nom */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="pri_nom"
                  label="Primer Nombre"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.pri_nom}
                  value={values.pri_nom || ""}
                  error={errors.pri_nom}
                />
              </Grid>
              {/* seg_nom */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="seg_nom"
                  label="Segundo Nombre"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.seg_nom}
                  value={values.seg_nom || ""}
                  error={errors.seg_nom}
                />
              </Grid>
              {/* pri_ape */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="pri_ape"
                  label="Primer Apellido"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.pri_ape}
                  value={values.pri_ape || ""}
                  error={errors.pri_ape}
                />
              </Grid>
              {/* seg_ape */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="seg_ape"
                  label="Segundo Apellido"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.seg_ape}
                  value={values.seg_ape || ""}
                  error={errors.seg_ape}
                />
              </Grid>
              {/* sexo */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{ padding: "1em" }}
              >
                <QuickSearch
                  name="sexo"
                  label="Sexos"
                  keyItem="id"
                  valueItem="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.sexo || ""}
                  error={errors.sexo}
                  touched={touched.sexo}
                  isQuickSearch={false}
                  data={sexos}
                />
              </Grid>
              {/* fec_nac */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldDate
                  value={values.fec_nac}
                  setFieldValue={setFieldValue}
                  label="Fecha nacimiento*"
                  name="fec_nac"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.fec_nac}
                  errorDate={errors.fec_nac}
                />
              </Grid>
              {/* nivel_educ */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{ padding: "1em" }}
              >
                <QuickSearch
                  name="nivel_educ"
                  label="Nivel educativo"
                  keyItem="id"
                  valueItem="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nivel_educ}
                  error={errors.nivel_educ}
                  touched={touched.nivel_educ}
                  isQuickSearch={false}
                  data={nivelesEducativos}
                />
              </Grid>
              {/* estrato */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{ padding: "1em" }}
              >
                <QuickSearch
                  name="estrato"
                  label="Estrato"
                  keyItem="id"
                  valueItem="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.estrato || ""}
                  error={errors.estrato}
                  touched={touched.estrato}
                  isQuickSearch={false}
                  data={estratos}
                />
              </Grid>
              {/* correo */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="correo"
                  label="Correo Electrónico"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.correo}
                  value={values.correo || ""}
                  error={errors.correo}
                />
              </Grid>
              {/* num_cel */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="num_cel"
                  label="Número Celular"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.num_cel}
                  value={values.num_cel || ""}
                  error={errors.num_cel}
                />
              </Grid>
              {/* direc */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="direc"
                  label="Dirección"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.direc}
                  value={values.direc || ""}
                  error={errors.direc}
                />
              </Grid>
              {/* id_departamento */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{ padding: "1em" }}
              >
                <QuickSearch
                  name="id_departamento"
                  label="Departamentos"
                  keyItem="id"
                  valueItem="nombre"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.id_departamento}
                  error={errors.id_departamento}
                  touched={touched.id_departamento}
                  isQuickSearch={false}
                  data={departamentos}
                />
              </Grid>
              {/* id_localidad */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{ padding: "1em" }}
              >
                <QuickSearch
                  name="id_localidad"
                  label="Municipios*"
                  keyItem="id"
                  valueItem="nombre"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.id_localidad}
                  error={errors.id_localidad}
                  touched={touched.id_localidad}
                  isQuickSearch={false}
                  data={municipios}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default ParticipanteForm;
