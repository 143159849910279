import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Paper,
  Stack,
  IconButton,
  Icon,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Slide,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel
} from "@mui/material";
import Loading from "components/Loading";
import ButtonIcon from "components/ui/Buttons/ButtonIcon";
import TableDataGrid from "components/ui/Tables/TableDataGrid";
import {
  showAlert,
  showAlertConfirm,
  showAlertError,
  showAlertSuccess,
} from "utils/alert";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import userService from "services/user.services";
import HeaderPage from "components/HeaderPage";
import Title from "components/Title";
import paramsService from "services/params.services";
import ParticipanteForm from "../components/PruebaForm";
import participantesAdapters from "../adapters/pruebas.adapters";
import testService from "services/test.services";
import pruebasAdapter from "../adapters/pruebas.adapters";
import PruebaForm from "../components/PruebaForm";
import adminService from "services/admin.services";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ModalParams = ({ mostrarRI, setMostrarRI, data, setOpenLoading, init, rol }) => {
  useEffect(() => {
    // console.log(data);
  }, [mostrarRI]);

  const handleClickOpen = () => {
    setMostrarRI(true);
  };

  const handleClose = () => {
    setMostrarRI(false);
  };
  return (
    <>
      <Dialog
        open={mostrarRI}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="lg"
        sx={{ zIndex: (theme) => theme.zIndex.drawer - 150 }}
      >
        <DialogContent dividers>
          {mostrarRI && (
            <PruebaForm
              setOpenLoading={setOpenLoading}
              data={data}
              isAdd={!data ? true : false}
              setMostrarRI={setMostrarRI}
              initPadre={init}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Grid
              item
              xs={11}
              sx={{ pl: "2em" }}
            >
              <Typography variant="h6" color="primary">{data > 0 ? "Actualizar " : "Crear "} Pruebas</Typography>
            </Grid>
            <Grid
              item
              xs={1}
            >
              <Button onClick={handleClose}>Cerrar</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
const headers = [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 80,
    hide: true
  },
  {
    field: "prueba",
    headerName: "Código",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 120,
    // hide: true
  },
  {
    field: "tipo",
    headerName: "Tipo",
    flex: 2,
    minWidth: 150
  },
];
const initialValues = {
  colegio: "",
  grado: "",
  grupo: ""
};
const formSchema = Yup.object().shape({
  colegio: Yup.string().required("Campo Requerido"),
  grado: Yup.string().required("Campo Requerido"),
  grupo: Yup.string().required("Campo Requerido")
});

const ListPruebas = () => {
  const [openLoading, setOpenLoading] = useState(false);
  const [pruebas, setPruebas] = useState([]);
  const [usersFilters, setUsersFilters] = useState([]);
  const [columns, setColumns] = useState(headers);
  const [colegios, setColegios] = useState([]);
  const [grados, setGrados] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [filterTipos, setFilterTipos] = useState(1);
  const [mostrarRI, setMostrarRI] = useState(false);
  const [data, setData] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const changeStatusUser = async (id, estado) => {
    const data = {
      usuario: id,
      estado,
    };
    try {
      setOpenLoading(true);
      var response = await userService.changeStatusUser(data);
      if (response.status === 200) {
        setOpenLoading(false);
        showAlertSuccess("Usuario " + estado === 1 ? "activado" : "inactivado");
      }
    } catch (error) {
      console.log(error);
      setOpenLoading(false);
      showAlertError("Error al inactivar el usuario!");
    }
  };
  async function getPruebas(row) {
    try {
      setOpenLoading(true);
      var response = await testService.getPruebasParam();
      if (response.status === 200) {
        setOpenLoading(false);
        setPruebas(pruebasAdapter.getPruebasAdapter(response.data.data));
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');
      setOpenLoading(true);
    }
  }
  const handleClickFilter = (event, resetForm) => {
    setFilterTipos(event.target.value);
    // getParticipantes(event.target.value);
    resetForm();
  };
  const setHeaders = () => {
    let array = [...headers];
    let actions = [
      {
        field: "ver",
        headerName: "",
        flex: 0.1,
        sortable: false,
        renderCell: (cellValues) => (
          <>
            <Stack direction="row" spacing={1}>
              <IconButton
                aria-label="ver"
                onClick={async () => {                  
                  const data = {
                    "prueba": cellValues.row.prueba
                  }
                  const response = await adminService.getDetailTest(data);                  
                  setData(pruebasAdapter.getPruebasDetalleAdapter(response.data.data));
                  setMostrarRI(true);
                }}>
                <Icon color="info">visibility</Icon>
              </IconButton>
            </Stack>
          </>
        ),
        disableClickEventBubbling: true,
      },
      {
        field: "changeStatus",
        headerName: "",
        flex: 0.1,
        sortable: false,
        renderCell: (cellValues) => (
          <>
            <Stack direction="row" spacing={1}>
              <IconButton
                disabled
                aria-label="toggle_off"
                onClick={() => {
                  showAlertConfirm(
                    "warning",
                    "Eliminar Colegio",
                    "¿Desea eliminarlo?",
                    async function (confirmed) {
                      if (confirmed) {
                        const status = cellValues.row.estado === 1 ? 0 : 1;
                        await changeStatusUser(cellValues.row.id, status).then(
                          () => {
                            init();
                          }
                        );
                      }
                    }
                  );
                }}
              >
                <Icon color="info">delete</Icon>
              </IconButton>
            </Stack>
          </>
        ),
        disableClickEventBubbling: true,
      },
    ];
    actions.forEach((elements) => {
      array.push(elements);
    });
    setColumns(array);
  };
  const init = () => {
    // getTiposParticipantes();
    getPruebas();
    // setUsers([]);
    // getUsers(users);
  };
  useEffect(() => {
    setHeaders();
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Loading openLoading={openLoading} />
      <Title title="Dashboard - Pruebas" />
      <HeaderPage descripcion={"Pruebas"} />
      <Paper
        elevation={5}
        sx={{
          margin: "1em",
          minHeight: "86vh",
          //minWidth: "85vh",
        }}
      >
        <ModalParams
          mostrarRI={mostrarRI}
          setMostrarRI={setMostrarRI}
          data={data}
          setOpenLoading={setOpenLoading}
          init={init}
          rol={filterTipos}
        />
        {/* Toolbar */}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            bgcolor: "background.paper",
            background: theme.palette.secondary.light,
            padding: "0,5em",
          }}
        >
          <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
            <ButtonIcon
              id="btnSubmit"
              fullWidth
              iconname="add_circle"
              description="Agregar"
              color="primary"
              onClick={() => {
                setData(null);
                setMostrarRI(true);
              }}
            />
          </Grid>
        </Grid>
        {/* Page */}
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {

          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            setFieldValue,
            /* and other goodies */
          }) => (
            <Box
              component="form"
              noValidate
              id="asignar-pruebas-form"
              sx={{ mt: 1 }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  padding: "1em",
                }}
              >
                {/* Radio */}
                {/*<Grid
                  item
                  xs={12}
                  sx={{ padding: "1em 1em 1em 1em" }}
                >
                  <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      Tipos de participantes
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={filterTipos}
                      onChange={(event) => handleClickFilter(event, resetForm)}
                    >
                      {tipoParticipantes && (
                        tipoParticipantes.map((item) => (
                          <>
                            <FormControlLabel key={item.key}
                              value={item.key}
                              control={<Radio />}
                              label={item.value}
                            />
                          </>
                        ))
                      )}
                    </RadioGroup>
                  </FormControl>
                </Grid>*/}
                {/* Usuarios */}
                {pruebas.length > 0 && (
                  <Grid item xs={12}>
                    <TableDataGrid
                      tittle={"Listado de pruebas"}
                      data={pruebas}
                      columns={columns}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Formik>
      </Paper>
    </>
  );
};

export default ListPruebas;