import React, { useState, useEffect } from "react";
import {
  Grid,
  FormHelperText,
  InputLabel,
  FormControl,
  Box,
  MenuItem,
  Button,
  TextField,
  Stack
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { Formik } from "formik";
import { downloadExcel } from "react-export-table-to-excel";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { DataGrid } from "@mui/x-data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import testService from "services/test.services";
import moment from "moment";
import * as Yup from "yup";
import SelectCustom from "components/ui/Selects/SelectCustom";
import { columnsDGrid } from "utils/functions";
import {
  showAlertError,
  showAlertInfo,
} from "utils/alert";
import SelectAutocomplete from "components/ui/Selects/SelectAutocomplete";

const initialValues = {
  fechaInicio: moment(),
  fechaFin: moment(),
  colegio: "",
  grado: "",
  grupo: "",
  tipoPrueba: "",
  prueba: "",
  codEstudiante: "",
};

const formSchema = Yup.object().shape({
  fechaInicio: Yup.date().required("Campo Requerido"),
  fechaFin: Yup.date().required("Campo Requerido")
});

const ReportTestForm = ({ setOpenLoading }) => {
  const [colegios, setColegios] = useState([]);
  const [grados, setGrados] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [tipoPruebas, setTipoPruebas] = useState([]);
  const [pruebas, setPruebas] = useState([]);
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [columns, setColumns] = useState([]);
  const [evaluacionesKey, setEvaluacionesKey] = useState([]);
  const [header, setHeader] = useState([]);

  async function getColegios() {
    try {
      setOpenLoading(true);
      var response = await testService.resultTest_GetColegios();
      if (response.status === 200) {
        setColegios(response.data.data);
        setOpenLoading(false);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
      setOpenLoading(false);
    }
  }
  async function getGrados(data) {
    try {
      var response = await testService.resultTest_GetGrados(data);
      if (response.status === 200) {
        setGrados(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getGrupos(data) {
    try {
      var response = await testService.resultTest_GetGrupos(data);
      if (response.status === 200) {
        setGrupos(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getTipoPruebas() {
    try {
      setOpenLoading(true);
      var response = await testService.resultTest_GetTipoPruebas();
      if (response.status === 200) {
        setTipoPruebas(response.data.data);
        setOpenLoading(false);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
      setOpenLoading(false);
    }
  }
  async function getPruebas(tipoPrueba) {
    try {
      setPruebas(tipoPruebas.find((e) => e.key === tipoPrueba).pruebas);
      /*var response = await client('POST', {}, '/tests/' + tipoPrueba);
      if (response.status === 200) {
        setPruebas(response.data.data);
      }*/
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getEvaluacionesDetallado(data, resetForm) {
    try {
      setOpenLoading(true);
      var response = await testService.reportTest_GetEvaluacionesDetallado(
        data
      );
      if (response.status === 200) {
        setOpenLoading(false);
        if (response.data.data.resultados.length > 0) {
          let data = columnsDGrid(
            response.data.data.keys,
            response.data.data.titulos,
            response.data.data.resultados
          );
          setEvaluaciones(response.data.data.resultados.sort((a, b) => b.id - a.id));

          setColumns(data);
          setEvaluacionesKey(response.data.data.keys);
          setHeader(response.data.data.titulos);
        } else {
          showAlertInfo("No se encontraron datos");
        }
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("No se encontraron datos");
    }
  }

  let submitAction;

  function handleDownloadExcel() {
    let tempResultados = [];

    evaluaciones.forEach(element => {
      let objTemp = {};
      evaluacionesKey.forEach(elemKey => {
        if (element[elemKey]) {
          objTemp[elemKey] = element[elemKey];
        } else {
          objTemp[elemKey] = "";
        }
      });
      tempResultados.push(objTemp)
    });

    downloadExcel({
      fileName: "Resultados estudiantes",
      sheet: "Resultados",
      tablePayload: {
        header,
        body: tempResultados,
      },
    });
    //clearForm();
  }

  const clearForm = () => {
    setColegios([]);
    setGrados([]);
    setGrupos([]);
    setTipoPruebas([]);
    setPruebas([]);
    initForm();
  };

  const initForm = () => {
    getColegios();
    getTipoPruebas();
  };

  useEffect(() => {
    initForm();
  }, []);
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        validate={(values) => {
          const errors = {};
          if (
            moment(values.fechaInicio).valueOf() >
            moment(values.fechaFin).valueOf()
          ) {
            errors.fechaInicio = "No puede asignar";
            errors.fechaFin = "No puede asignar";
          }
          if (values.colegio) {
            let data = {
              colegio: values.colegio,
            };
            getGrados(data);
          }
          if (values.grado) {
            let data = {
              colegio: values.colegio,
              grado: values.grado,
            };
            getGrupos(data);
          }
          if (values.tipoPrueba) {
            getPruebas(values.tipoPrueba);
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (submitAction === "buscar") {
            var data = null;
            data = {
              tipo: values.tipoPrueba,
              fechaInicio: moment(values.fechaInicio).format("YYYY-MM-DD"),
              fechaFin: moment(values.fechaFin).format("YYYY-MM-DD")
            };
            if (values.prueba) {
              data.prueba = values.prueba;
            }
            if (values.grado) {
              data.grado = values.grado;
            }
            if (values.curso) {
              data.curso = values.curso;
            }
            if (values.colegio) {
              data.colegio = values.colegio;
            }
            setEvaluaciones([]);
            getEvaluacionesDetallado(data, resetForm);
          } else {
            handleDownloadExcel();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Box
            component="form"
            noValidate
            id="resutado-pruebas-form"
            sx={{ mt: 1 }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                bgcolor: "background.paper",
                borderRadius: 1,
              }}
            >
              {/* Tipo Pruebas */}
              <Grid item xs={12} sm={12} md={6} lg={3} sx={{ padding: "1em" }}>
                <FormControl fullWidth error={errors.prueba}>
                  <InputLabel id="input-label-inst">Tipos Pruebas*</InputLabel>
                  <SelectCustom
                    labelId="select-label-tipo-prueba"
                    id="select-tipo-prueba"
                    name="tipoPrueba"
                    label="tipos pruebas*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tipoPrueba}
                  >
                    <MenuItem value="">Todos</MenuItem>
                    {tipoPruebas &&
                      tipoPruebas.map((item) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                  </SelectCustom>
                  {errors.tipoPrueba && touched.tipoPrueba && (
                    <FormHelperText>Campo Requerido</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* Pruebas */}
              <Grid item xs={12} sm={12} md={6} lg={5} sx={{ padding: "1em" }}>
                <FormControl fullWidth error={errors.prueba}>
                  <InputLabel id="input-label-inst">Pruebas*</InputLabel>
                  <SelectCustom
                    labelId="select-label-prueba"
                    id="select-prueba"
                    name="prueba"
                    label="pruebas*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.prueba}
                  >
                    <MenuItem value="">Todos</MenuItem>
                    {pruebas &&
                      pruebas.map((item) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                  </SelectCustom>
                  {errors.prueba && touched.prueba && (
                    <FormHelperText>Campo Requerido</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <>
                {/* FechaInicio */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={2}
                  sx={{ padding: "1em" }}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Stack spacing={3}>
                      <DatePicker
                        onChange={(value) =>
                          setFieldValue("fechaInicio", value)
                        }
                        onBlur={(value) =>
                          setFieldValue("fechaInicio", value)
                        }
                        value={values.fechaInicio}
                        inputFormat="DD/MM/YYYY"
                        label="Fecha Inicio*"
                        renderInput={(params) => (
                          <TextField
                            label="Fecha Inicio"
                            name="FechaInicio"
                            error={
                              errors.fechaInicio && touched.fechaInicio
                                ? true
                                : false
                            }
                            helperText={
                              errors.fechaInicio && touched.fechaInicio
                                ? errors.fechaInicio
                                : null
                            }
                            {...params}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                {/* FechaFin */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={2}
                  sx={{ padding: "1em" }}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Stack spacing={3}>
                      <DatePicker
                        onChange={(value) => setFieldValue("fechaFin", value)}
                        onBlur={(value) => setFieldValue("fechaFin", value)}
                        value={values.fechaFin}
                        inputFormat="DD/MM/YYYY"
                        label="Fecha Fin*"
                        renderInput={(params) => (
                          <TextField
                            label="Fecha Fin"
                            name="fechaFin"
                            error={
                              errors.fechaFin && touched.fechaFin
                                ? true
                                : false
                            }
                            helperText={
                              errors.fechaFin && touched.fechaFin
                                ? errors.fechaFin
                                : null
                            }
                            {...params}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                {/* Colegios */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  sx={{ padding: "1em" }}
                >
                  <SelectAutocomplete
                    name="colegio"
                    label="Colegios*"
                    onOpen={getColegios}
                    setFieldValue={setFieldValue}
                    options={colegios}
                    error={errors.colegio}
                    touched={String(touched.colegio)}
                    id="id"
                    desc="nombre"
                  />
                </Grid>
                {/* Grados */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={2}
                  sx={{ padding: "1em" }}
                >
                  <FormControl fullWidth error={errors.grado}>
                    <InputLabel id="input-label-grado">Grados</InputLabel>
                    <SelectCustom
                      labelId="select-label-grado"
                      id="select-grado"
                      name="grado"
                      label="grados"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.grado}
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {grados &&
                        grados.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.nombre}
                          </MenuItem>
                        ))}
                    </SelectCustom>
                    {errors.grado && touched.grado && (
                      <FormHelperText>Campo Requerido</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {/* Grupos */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={2}
                  sx={{ padding: "1em" }}
                >
                  <FormControl fullWidth error={errors.grupo}>
                    <InputLabel id="input-label-inst">Grupos</InputLabel>
                    <SelectCustom
                      labelId="select-label-grupo"
                      id="select-grupo"
                      name="grupo"
                      label="grupos"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.grupo}
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {grupos &&
                        grupos.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.nombre}
                          </MenuItem>
                        ))}
                    </SelectCustom>
                    {errors.grupo && touched.grupo && (
                      <FormHelperText>Campo Requerido</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
              {/* Buscar */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none", height: "3em" }}
                  startIcon={<SearchTwoToneIcon />}
                  onClick={() => {
                    submitAction = "buscar";
                    handleSubmit();
                  }}
                >
                  Buscar
                </Button>
              </Grid>
              {/* Limpiar */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  sx={{ textTransform: "none", height: "3em" }}
                  startIcon={<CleaningServicesIcon />}
                  onClick={() => {
                    clearForm();
                    resetForm();
                  }}
                >
                  Limpiar
                </Button>
              </Grid>
              {/* Evaluaciones */}
              {evaluaciones.length > 0 && (
                <>
                  <Grid item sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                    <Button
                      type="button"
                      fullWidth
                      variant="text"
                      sx={{ textTransform: "none", height: "3em" }}
                      startIcon={<SystemUpdateAltIcon />}
                      onClick={() => {
                        submitAction = "habilitar";
                        handleSubmit();
                      }}
                    >
                      Descargar Excel
                    </Button>
                  </Grid>
                  <div style={{ height: 420, width: "100%", padding: "1em" }}>
                    <DataGrid rows={evaluaciones} columns={columns} />
                  </div>
                </>
              )}
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default ReportTestForm;
